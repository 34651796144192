import React from 'react';
import loadable from '@loadable/component';

import {
  HOME_NAV, LOYALTY_CARD_NAV, EMAIL_NAV,
} from 'constants/font-awesome';
import {
  HOME_ROUTE, ALL_CARDS_ROUTE, CONTACT_US,
} from 'constants/navigation';

const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));
const MenAtWork = loadable(() => import('components/men-at-work'));

const MenAtWorkView = () => (
    <>
      <MenAtWork/>
      <LazyOverrideFooter
        navItems={[
          { route: HOME_ROUTE, icon: HOME_NAV, message: 'Home' },
          { route: ALL_CARDS_ROUTE, icon: LOYALTY_CARD_NAV, message: 'Cards' },
          { route: CONTACT_US, icon: EMAIL_NAV, message: 'Contact' },
        ]}
        currentItem=""/>
    </>
);
export default MenAtWorkView;
