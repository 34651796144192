import React from 'react';
import Page from 'components/page';
import MenAtWorkView from 'views/men-at-work';

const UpdateMessagePage = () => (
    <Page title="Message card">
      <MenAtWorkView />
    </Page>
);
export default UpdateMessagePage;
